import LoginRequiredGuard from 'modules/auth/components/LoginRequiredGuard';
import Courses from 'modules/dashboard/components/Courses';

const CoursesPage = () => {
  return (
    <LoginRequiredGuard>
      <Courses></Courses>
    </LoginRequiredGuard>
  );
};

export default CoursesPage;
