import { Box, CircularProgress } from '@mui/material';
import { navigate } from 'gatsby';

import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showFlashMessage } from 'modules/ui/slice';

import { selectIsLoggedIn, selectProfileLoaded } from '../selectors';

type LoginRequiredGuardProps = {
  children: ReactNode;
};

const LoginRequiredGuard = ({ children }: LoginRequiredGuardProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isProfileLoaded = useSelector(selectProfileLoaded);

  if (!isProfileLoaded) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        <CircularProgress></CircularProgress>
      </Box>
    );
  }

  if (!isLoggedIn) {
    navigate('/', { replace: true });
    dispatch(
      showFlashMessage({
        type: 'error',
        message: 'กรุณาเข้าสู่ระบบก่อนเข้าถึงหน้าดังกล่าว',
      })
    );
    return null;
  }

  return <>{children}</>;
};

export default LoginRequiredGuard;
